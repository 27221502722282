import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {I18nService, ToastService} from 'src/app/shared/services';
import {AdminSimpleSamplesService} from '../../../services/admin-simple-samples.service';
import {SampleScoreCard, ScoreCardValues, SimpleOrder} from '../../../models/simple-order-response';
import {Deadline} from '../../../../models/deadline';
import {DropdownModel} from '../../../../shared/components/dropdown/dropdown.model';
import {TranslateService} from '@ngx-translate/core';
import {FunctionsService} from '../../../services/functions.service';
import {Order} from '../../../models/order';
import {ResourcesService} from '../../../../shared/services/resources.service';

@Component({
  selector: 'app-prolabo-pagination-list-simple-samples',
  templateUrl: './pagination-list-simple-samples.component.html',
  styleUrls: ['./pagination-list-simple-samples.component.scss']
})
export class PaginationListSimpleSamplesComponent implements OnInit {

  @Input() items: SampleScoreCard[];
  @Input() deadline: Deadline;
  @Input() headers: any[];
  @Input() isVdi = false;
  @Input() userSide = false;
  @Input() scoreCardModels: any[];
  @Input() totalItems;
  @Input() listType;
  @Input() routerLinkDetails;
  @Input() step2: boolean;
  @Input() step1: boolean;
  @Input() step3: boolean;
  @Input() step4: boolean;
  @Input() stepDone: boolean;
  @Input() sampleAnalyzed: boolean;
  @Input() user: boolean;
  @Input() order: Order | SimpleOrder;
  @Input() isCanceled = false;

  @Output() sortValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() limitValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemToEdit: EventEmitter<number> = new EventEmitter<number>();
  @Output() getOrder: EventEmitter<boolean> = new EventEmitter<boolean>();
  page = 0;
  numberRows = 10;

  displayModal = false;
  itemToDelete = null;

  itemToFocus: number = null;
  newItemValue: number = null;
  sortHeaderValues = [];
  sampleType: any;

  constructor(private router: Router,
              private lang: I18nService,
              private translate: TranslateService,
              private samplesService: AdminSimpleSamplesService,
              private functions: FunctionsService,
              private resources: ResourcesService,
              private toast: ToastService) {
  }

  ngOnInit() {
    console.log(this.order);
    this.sampleType = this.items[0].sample_type_id;
    if (this.sampleType === 2 && !this.user) {
      const item = this.scoreCardModels.find(x => x.slug === 'publish_remark');
      const index = this.scoreCardModels.indexOf(item);

      if (item && index) {
        this.scoreCardModels[index].field_name = 'INITIALS';
        this.scoreCardModels[index].slug = 'initials';
      }
    }
    if (this.sampleAnalyzed && !this.userSide) {
      this.makeScoreCardValues();
      if (this.sampleType === 2) {
        this.changeScoreCard();
      }
    }

    if (this.isVdi) {
      this.headers.splice(3);
    }

  }

  onFocus(event) {
    this.itemToFocus = event.target.getAttribute('id');
  }

  makeScoreCardValues() {
    this.items.forEach(x => {
      const values: any = new Array(0);
      this.scoreCardModels.forEach(y => {
        const found = x.scorecard_value.find(z => y.id === z.scorecard_field_id);
        if (found) {
          if (found.scorecard_field.slug !== 'publish_remark') {
            values.push(found);
          }
          if (found.scorecard_field_id === 3 && this.sampleType === 2) {
            const result: ScoreCardValues =
              {
                ...found,
                id: 888,
                scorecard_field_id: 888,
                scorecard_model_id: 2,
                text_value: `${found.user.first_name[0]}${found.user.last_name[0]}`,
                scorecard_field_option: null,
                scorecard_field_option_id: null,
                scorecard_field: {
                  ...found.scorecard_field,
                  field_name: 'INITIALS',
                  slug: 'initials',
                  scorecard_field_type_id: 888,
                  id: 888
                },
              };
            values.push(result);
          }
        } else {
          // @ts-ignore
          if (y.slug !== 'initials') {
            values.push(true);
          }
        }
      });
      x.scorecard_values = values;
    });
  }

  onChange(event, item) {
    this.newItemValue = event.target.value;
  }

  getDropdownTranslations(id: number, typeId: number): string {
    if (typeId === 2) {
      if (id === 1) {
        return 'ASBESTOS_NOT_DETECTED';
      } else if (id === 2) {
        return 'ASBESTOS_DETECTED_ACTINOLITE';
      } else if (id === 3) {
        return 'ASBESTOS_DETECTED_AMOSITE';
      } else if (id === 4) {
        return 'ASBESTOS_DETECTED_ANTHOPHYLLITE';
      } else if (id === 12) {
        return 'ASBESTOS_DETECTED_CHRYSOTILE';
      } else if (id === 13) {
        return 'ASBESTOS_DETECTED_CROCIDOLITE';
      } else if (id === 14) {
        return 'ASBESTOS_DETECTED_TREMOLITE';
      }
    } else if (typeId === 3) {
      if (id === 5) {
        return 'LEAD_NOT_DETECTED';
      } else if (id === 6) {
        return 'LEAD_BELOW';
      } else if (id === 7) {
        return 'LEAD_UNDER';
      }
    } else if (typeId === 5) {
      if (id === 12) {
        return 'HAP_NOT_DETECTED';
      } else if (id === 13) {
        return 'HAP_DETECTED';
      }
    }
    return null;
  }

  public setDropdownOptions(option, sampleType): DropdownModel[] {
    const value = this.getDropdownTranslations(option.id, sampleType);
    return value ? this.translate.instant(value) : option.value;
  }

  updateSampleType(item) {
    this.samplesService.updateSampleType({
      id: item.id,
      client_id: this.newItemValue,
      locale: this.lang.currentLang
    }).subscribe(res => {
      this.toast.showBottomCenterSuccess(res.message);
      this.newItemValue = null;
      this.itemToFocus = null;
    }, error => {
      this.functions.showError(error);
    });
  }

  sortByLabel(param: string) {
    this.clearOtherValues(param);
    const sortValue = this.sortHeaderValues.filter(x => x.param === param)[0];
    sortValue.asc = !sortValue.asc;

    this.sortValue.emit(sortValue);
  }

  deleteItem() {
    this.itemDelete.emit(this.itemToDelete);
    this.displayModal = false;
    this.itemToDelete = null;
  }

  openConfirmDialog(id) {
    this.displayModal = true;
    this.itemToDelete = id;
  }

  closeConfirmDialog() {
    this.itemToDelete = null;
    this.displayModal = false;
  }

  getLastHeader() {
    return this.headers[this.headers.length - 1];
  }

  clearOtherValues(param) {
    this.sortHeaderValues.forEach(header => {
      if (param !== header.param) {
        header.asc = true;
      }
    });
  }

  getAscValueForHeader(header) {
    return this.sortHeaderValues.filter(x => x.param === header)[0].asc;
  }

  onPageChange(event) {
    this.page = event.page;
    this.pageValue.emit(event.page);
  }

  onLimitChange(event) {
    this.numberRows = event.value;
    this.page = 0;
    this.limitValue.emit(event.value);
  }

  onItemClicked(item) {
    this.itemClicked.emit(item);
  }

  navigate(id) {
    if (this.routerLinkDetails) {
      this.router.navigate([this.routerLinkDetails, id]);
    }
  }

  getSampleValue(slug: any, scorecardField: ScoreCardValues[], sampleType) {
    const fields = scorecardField.filter(x => x.scorecard_field?.slug === slug);
    let value = null;
    let field;
    if (slug !== 'colors') {
      field = fields[0];
    }

    if (field) {
      value = this.getResult(field, sampleType);
    }

    if (fields.length > 1) {
      value = '';
      fields.forEach(x => {
        value += this.getResult(x, sampleType) + ', ';
      });
    }

    return value;
  }

  getResult(field: ScoreCardValues, sampleType: string) {
    let value = null;
    if (field.scorecard_field?.scorecard_field_type_id === 1) {
      value = field.text_value;
    } else if (field.scorecard_field?.scorecard_field_type_id === 2) {
      value = field.user?.initials;
    } else if (field.scorecard_field?.scorecard_field_type_id === 6 || field.scorecard_field?.scorecard_field_type_id === 5) {
      // ts-ignore
      value = this.translate.instant(field.scorecard_field_option.value);
    } else if (field.scorecard_field?.scorecard_field_type_id === 3 && field.scorecard_field_option) {
      value = this.setDropdownOptions(field.scorecard_field_option, sampleType);
    } else if (field.scorecard_field_id === 60 ||
      field.scorecard_field_id === 61 ||
      field.scorecard_field_id === 66 ||
      field.scorecard_field_id === 67 ||
      field.scorecard_field_id === 68 ||
      field.scorecard_field_id === 69 ||
      field.scorecard_field_id === 70 ||
      field.scorecard_field_id === 71 ||
      field.scorecard_field_id === 888) {
      value = field.text_value;
    }
    return value;
  }

  updateSample(id) {
    this.itemToEdit.emit(id);
  }

  splitSample(id) {
    const body = {
      order_id: this.order.id,
      sample_id: id,
      locale: this.resources.getLanguage().description
    };

    this.samplesService.splitSample(body).subscribe(result => {
      this.getOrder.emit(true);
    }, error =>
      this.functions.showError(error));
  }

  private changeScoreCard() {
    this.items.forEach(x => {
      const item = x.scorecard_value.find(x => x.scorecard_field_id === 3);
      if (item) {
        const result: ScoreCardValues =
          {
            ...item,
            id: 888,
            scorecard_field_id: 888,
            scorecard_model_id: 2,
            text_value: `${item.user.first_name[0]}${item.user.last_name[0]}`,
            scorecard_field_option: null,
            scorecard_field_option_id: null,
            scorecard_field: {
              ...item.scorecard_field,
              field_name: 'INITIALS',
              slug: 'initials',
              scorecard_field_type_id: 4,
              id: 888
            },
          };
        x.scorecard_value.push(result);
      }
    });
  }
}
