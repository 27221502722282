import {Component, OnInit} from '@angular/core';
import {ToastService, UserService} from '../../../../shared/services';
import {DropdownModel} from 'src/app/shared/components/dropdown/dropdown.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ResourcesService} from '../../../../shared/services/resources.service';
import {AdminUsersService} from '../../../../admin/services/admin-users.service';
import {TranslateService} from '@ngx-translate/core';
import {AdminOrdersService} from '../../../../admin/services/admin-orders.service';
import {DialogService} from 'primeng/dynamicdialog';
import {Deadline} from '../../../../models/deadline';
import {ProlaboAnalyseService} from '../../../services/prolabo-analyse.service';
import {AdminStatics} from '../../../../admin/statics/admin-statics';
import {
  CreateOrderComponent
} from '../../../../admin/pages/orders/modals/create-order-modal/create-order.component';
import {AdminSimpleOrdersService} from '../../../../admin/services/admin-simple-orders.service';
import {AdminDiagnosticOrdersService} from '../../../../admin/services/admin-diagnostic-orders.service';
import {FunctionsService} from '../../../../admin/services/functions.service';
import {AdminDashboardService} from '../../../../admin/services/admin-dashboard.service';
import {Laboratory} from '../../../../models/laboratorie';
import {Type} from '../../../../admin/models/type';
import {User} from '../../../../admin/models/user';

@Component({
  selector: 'app-prolabo-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent implements OnInit {
  orders: [];
  totalOrders;
  sortParams;
  searchParam;
  orderTypeId: number = null;
  deadlines: DropdownModel[] = [];
  laboratories: Laboratory[] = [];
  laboratoryIds: number[];
  samples: any;
  totalSamples;
  limit = 10;
  offset = 0;
  stateIds = [];
  stepParam: number;
  sampleTypes: Type[];
  availableSampleTypes: Type[];
  sampleTypeId: number;
  filterByCity: boolean;
  currentUser: User;
  selectedLaboratory: number;


  headers = [
    {title: 'NUMBER', sortLabel: AdminStatics.orderNumber},
    {title: 'SITE', sortLabel: AdminStatics.site},
    {title: 'STATE', sortLabel: AdminStatics.latestState},
    {title: 'SAMPLE', sortLabel: AdminStatics.sample},
    {title: 'BRANCH', sortLabel: AdminStatics.branch},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];

  orderHeaders = [
    {title: 'NUMBER', sortLabel: AdminStatics.orderNumber},
    {title: 'SITE', sortLabel: AdminStatics.site},
    {title: 'STATE', sortLabel: AdminStatics.latestState},
    {title: 'SAMPLE', sortLabel: AdminStatics.sample},
    {title: 'BRANCH', sortLabel: AdminStatics.branch},
    {title: 'DATE', sortLabel: AdminStatics.createdAt}
  ];

  constructor(
    private adminService: AdminUsersService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    public functions: FunctionsService,
    private translate: TranslateService,
    private simpleOrdersService: AdminSimpleOrdersService,
    private diagnosticOrdersService: AdminDiagnosticOrdersService,
    private dashboardService: AdminDashboardService,
    private analyseService: ProlaboAnalyseService,
    private ordersService: AdminOrdersService,
    private resources: ResourcesService,
    private userService: UserService,
    private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.selectedLaboratory = this.currentUser.role_id === 4 ? 0 : this.currentUser.laboratory_id;

    this.ordersService.getDeadlines({
      locale: this.resources.getLanguage().description,
    }).subscribe(result => {
      this.setDeadlines(result.deadlines);
    });

    if (this.selectedLaboratory === 0) {
      this.filterByCity = true;
      this.laboratoryIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }

    this.getOrders();
    this.getAllSampleTypes();
    this.getLaboratories();

    this.simpleOrdersService.getSampleTypes().subscribe(x => {
      this.availableSampleTypes = x;
    });

  }

  getLaboratories() {
    this.dashboardService.getLaboratories().subscribe(res => {
      this.laboratories = res.laboratories;
    });
  }

  getAllSampleTypes() {
    this.ordersService.getAllSampleTypes().subscribe(res => {
      this.sampleTypes = res;
    });
  }

  createOrderTemp() {
    if (this.currentUser.role.id !== 5) {
      const ref = this.dialogService.open(CreateOrderComponent, {
        showHeader: false,
        width: '70%',
        styleClass: 'dialog',
        data: {
          deadlines: this.deadlines,
          user: true,
          sampleTypes: this.availableSampleTypes,
          laboratories: this.laboratories,
          isUser: true
        },
      });
      ref.onClose.subscribe(res => {
        if (res) {
          const body = {
            order_type_id: 1,
            locale: this.resources.getLanguage().description,
            laboratory_id: res.laboratory_id,
            preselected_sample_type_id: res.sample_type_id,
            preselected_deadline_id: res.deadline_id,
          };
          const queryParams = {
            sample_type_id: res.sample_type_id,
          };
          this.simpleOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/user/orders/simple-order', result.order.id], {queryParams}).then(() => {
            });
          }, error => this.functions.showError((error)));
        }
      });
    } else {
      const body = {
        order_type_id: 1,
        locale: this.resources.getLanguage().description,
        laboratory_id: 11,
      };
      const queryParams = {
        sample_type_id: 8,
      };
      this.simpleOrdersService.orderCreate(body).subscribe(result => {
        this.router.navigate(['/user/orders/simple-order', result.order.id], {queryParams}).then(() => {
        });
      }, error => this.functions.showError((error)));
    }
  }

  createOrder() {
    const ref = this.dialogService.open(CreateOrderComponent, {
      showHeader: false,
      width: '70%',
      styleClass: 'dialog',
      data: {
        deadlines: this.deadlines,
        user: true,
        laboratories: this.laboratories,
        sampleTypes: this.availableSampleTypes,
        isUser: true
      },
    });

    ref.onClose.subscribe(res => {
      if (res) {
        if (res.type === AdminStatics.simpleOrder) {
          const body = {
            order_type_id: 1,
            locale: this.resources.getLanguage().description,
            laboratory_id: res.laboratory_id
          };
          const queryParams = {
            sample_type_id: res.sample_type_id,
          };
          this.simpleOrdersService.orderCreate(body).subscribe(result => {
            this.router.navigate(['/user/orders/simple-order', result.order.id], {queryParams}).then(() => {
            });
          }, error => this.functions.showError((error)));
        }
        // else if (res.type === AdminStatics.diagnostics) {
        //   const body = {
        //     order_type_id: 2,
        //     locale: this.resources.getLanguage().description,
        //     laboratory_id: res.laboratory_id
        //   };
        //   this.diagnosticOrdersService.orderCreate(body).subscribe(result => {
        //     this.router.navigate(['/user/orders/diagnostic-order', result.order.id]).then(() => {
        //     });
        //   }, error => this.functions.showError(error));
        // }
      }
    });
  }

  getOrders() {
    const params: any = {locale: this.resources.getLanguage().description};
    if (this.sortParams) {
      params.order_by = this.sortParams;
    }

    if (this.searchParam) {
      params.search = this.searchParam;
    }

    if (this.orderTypeId) {
      params.order_type_id = this.orderTypeId;
    }

    if (this.stateIds && this.stateIds.length > 0) {
      params.state_ids = this.stateIds;
    }

    if (this.sampleTypeId) {
      params.sample_type_id = this.sampleTypeId;
    }

    if (this.filterByCity) {
      params.laboratory_ids = this.laboratoryIds;
    }

    params[AdminStatics.limit] = this.limit;
    params[AdminStatics.offset] = this.offset;

    this.analyseService.getOrders(params).subscribe(res => {
      this.orders = res[1];
      this.totalOrders = res[0].orders?.total;
      this.totalSamples = res[0].samples?.total;
      this.samples = [];
      if (res[0].samples) {
        Object.entries(res[0].samples).map(sample => {
          if (sample[0] !== AdminStatics.total) {
            this.samples.push(sample[1]);
          }
        });
      }
    });

  }

  handleFilterByOrderCity($event: any) {
    this.filterByCity = true;
    if ($event === -1) {
      this.laboratoryIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else {
      this.laboratoryIds = [$event];
    }

    this.getOrders();

  }

  filterByOrderType(orderType: number = null) {
    this.orderTypeId = orderType;
    this.getOrders();
  }

  onSearchParamChange(param) {
    this.searchParam = param;
    this.getOrders();
  }

  private setDeadlines(types: Deadline[]) {
    types.forEach(type => {
      this.deadlines.push({
        id: type.id,
        text: this.translate.instant(type.slug),
        value: type.id
      });
    });
  }

  filterBySample(sampleId) {
    if (sampleId === -1) {
      this.sampleTypeId = null;
    } else {
      this.sampleTypeId = sampleId;
    }
    this.getOrders();
  }

  deleteParam(param) {
    this.stepParam = null;
    this.stateIds = [];
    this.router.navigate(
      ['.'],
      {relativeTo: this.route, queryParams: {}}
    );

    this.getOrders();
  }

  sortByLabel(param) {
    this.sortParams = param.asc ? param.param + AdminStatics.asc : param.param + AdminStatics.desc;
    this.getOrders();
  }

  changeLimit(limit) {
    this.limit = limit;
    this.offset = 0;
    this.getOrders();
  }

  changeOffset(page) {
    this.offset = page * this.limit;
    this.getOrders();
  }
}
