<div class="p-grid" *ngIf="order && scorecardModels">
  <div class="p-col-12 p-xl-8">
    <div class="main-title-container {{ stepDone ? 'secondary-container' : null }}">
      <div class="p-grid">
        <div class="p-col-12 p-md-6 title-block">
          <img src="../../../../../../../assets/icons/shopping-basket-small.svg">
          <span class="order-group">
            <p class="order-language">{{'SIMPLE.ORDER' | translate}} - {{order.user?.company}}</p>
            <p class="order-date {{ stepDone ? 'white-order-date' : null }}">
              {{order?.order_number}} {{ 'CREATED_ON' | translate }} {{order?.created_at | date }}</p>
          </span>
        </div>
        <div class="p-col-12 p-md-6 buttons-block">
          <div class="p-grid" style="margin-top: -22px;margin-bottom: -25px;">
            <div class="p-col-6">
              <ng-container
                *ngIf="order.latest_state?.name != 'admin.cancelled' && order.latest_state?.name !='client.cancelled'">
                <app-prolabo-icon-button [icon]="'close-white'"
                                         [name]="'CANCEL'"
                                         type="{{ stepDone ? 'secondary' : 'primary-radius' }}"
                                         class="{{ stepDone ? 'white-border' : null }}"
                                         (click)="openConfirmDialog2()">
                </app-prolabo-icon-button>
              </ng-container>
              <ng-container
                *ngIf="order.latest_state?.name == 'admin.cancelled' || order.latest_state?.name =='client.cancelled'">
                <app-prolabo-icon-button [icon]="'check'"
                                         [name]="'REACTIVATE_ORDER'"
                                         class="secondary"
                                         (click)="openConfirmDialog()">
                </app-prolabo-icon-button>
              </ng-container>
            </div>
            <div class="p-col-6" *ngIf="step1">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       [disabled]="!samplesAdded"
                                       name="VALIDATE"
                                       (OnClick)="submitOrder()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="step2">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="VALIDATE"
                                       (OnClick)="submitStep2()">
              </app-prolabo-icon-button>
            </div>

            <div class="p-col-6" *ngIf="step4 && Object.values(samplesByType)[0][0] !== '8'">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="VALIDATE"
                                       (OnClick)="submitStep4()">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="step4 && Object.values(samplesByType)[0][0] === '8'">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="VALIDATE"
                                       (OnClick)="openScoreCard(Object.values(samplesByType)[0][1].samples,
                                        Object.values(samplesByType)[0][1].analyzed ,
                                       true)">
              </app-prolabo-icon-button>
            </div>
            <div class="p-col-6" *ngIf="stepDone">
              <app-prolabo-icon-button icon="check"
                                       type="secondary"
                                       name="NEW_VERSION"
                                       class="white-border"
                                       (OnClick)="createNewVersion()">
              </app-prolabo-icon-button>
            </div>
          </div>
        </div>
        <div class="p-col-12 buttons-block" *ngIf="step1 || step2 || stepDone"
             style="margin-bottom: 0; margin-top: -10px">
          <div class="p-grid">
            <div class="p-col-6">
              <p style="margin: 0;  padding: 0" pTooltip="{{ 'DOWNLOAD' | translate }}"
                 tooltipPosition="top">
                <app-prolabo-icon-button [icon]="'download'"
                                         [name]="'DOWNLOAD'"
                                         type="primary-radius"
                                         class="white-border"
                                         (click)="downloadTemplate()">
                </app-prolabo-icon-button>
              </p>
            </div>
            <div class="p-col-6">
              <p style="margin: 0;  padding: 0" pTooltip="{{ 'IMPORT' | translate }}"
                 tooltipPosition="top">
                <app-prolabo-icon-input [for]="'import'"
                                        [id]="'import'"
                                        [type]="'file'"
                                        [importInput]="true"
                                        [inputType]="'basic'"
                                        [required]="false"
                                        [icon]="'upload-white'"
                                        [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                        [multiple]="false"
                                        keyword="IMPORT"
                                        class="regular-input-color"
                                        (change)="uploadTemplate($event)">
                </app-prolabo-icon-input>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-prolabo-order-step-container [step1]="stepCounter1"
                                      [step2]="stepCounter2"
                                      [step3]="stepCounter3"
                                      [step4]="stepCounter4"
    ></app-prolabo-order-step-container>
    <div class="final-step-container" *ngIf="stepDone">
      <div class="p-col-6">
        <app-prolabo-order-final-step-container [title]="'REPORT'"
                                                [secondButtonTitle]="'SEND_MAIL_REPORT'"
                                                [secondButtonIcon]="'upload2'"
                                                [reportsDate]="generatedReportDate"
                                                [textReport]="'REPORT_SENT'"
                                                [emailReport]="'EMAIL_SENT'"
                                                [emailDate]="generatedEmailDate"
                                                [firstButtonIcon]="'printer'"
                                                [firstButtonTitle]="order?.pdf_report_path === null ? 'EXPORT_REPORT': 'PRINT'"
                                                (firstButton)="printClicked()"
                                                (secondButton)="sendReportByMail(0)"
                                                [disableSecondButton]="!order?.pdf_report_path"
                                                [thirdButtonIcon]="'printer'"
                                                [thirdButtonTitle]="'REGENERATE'"
                                                [disableThirdButton]="!order?.pdf_report_path"
                                                [textControl]="'TEXT_CONTROL'"
                                                [noteControl]="'NOTE_CONTROL'"
                                                [noteValue]="order?.new_version_description"
                                                [dateControl]="order?.controller"
                                                (thirdButton)="regenerateReport()">
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-6" *ngIf="order.sosamiante_order_uuid == null">
        <app-prolabo-order-final-step-container [title]="'BILLING'"
                                                [firstButtonIcon]="'layer'"
                                                [textInvoices]="'INVOICES_GENERATED'"
                                                [invoicesNumber]="numGeneratedInvoices"
                                                [invoicesDate]="generatedInvoicesDate"
                                                [firstButtonTitle]="'GENERATE_INVOICES'"
                                                (firstButton)="generateInvoice()"
                                                [disableFirstButton]="isAnalyst"
                                                [secondButtonIcon]="'printer'"
                                                [secondButtonTitle]="order.invoice_generated === 1 ? order.invoices.invoice?.name : 'PRINT'"
                                                [disableSecondButton]="order.invoice_generated === 0 || isAnalyst"
                                                (secondButton)="printInvoice()"
        >
        </app-prolabo-order-final-step-container>
      </div>
      <div class="p-col-6" *ngIf="order.sosamiante_order_uuid">
        <div class="p-grid invoice-container ">
          <div class="p-col-12">
            <div class="p-grid">
              <div class="p-col-12 title">
                <p>{{'BILLING' | translate}}</p>
              </div>
              <div class="p-col-12" style="text-align: center; padding-top: 30%">
                <app-prolabo-icon icon="logo_blue"></app-prolabo-icon>
                <p>{{"BILLING_ON" | translate}} <span style="cursor:pointer; color: blue" (click)="openLink()"><u>sos-amiante.ch</u></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="samplesByType && samplesByType.length > 0">
      <ng-container *ngFor="let samples of samplesByType">
        <p-card>
          <div class="p-grid spacing-bottom">
            <div class="p-col-12 p-md-4 title">
              <p-header>
                {{ 'SAMPLING' | translate }} {{ functions.getTypeName(samples[0]) | translate }}
                ({{ samples[1].samples.length }})
              </p-header>
              <ng-container *ngIf="deadlines.hasOwnProperty(samples[0])" class="deadline-title">
                <div class="p-grid deadline-container" *ngIf="!stepDone">
                  <div class="p-col-12">
                    <app-prolabo-dropdown [options]="deadlines[samples[0]]"
                                          class="deadline-dropdown"
                                          [deadlineAt]="order.deadline_at"
                                          [chosenDeadlineId]="order.deadline_id"
                                          (OnValueChange)="updateDeadlineValue($event, samples[0])"
                                          [defaultValue]="defaultDeadlineValue"
                                          [disabled]="isCanceled"
                                          placeholder="DEADLINE"></app-prolabo-dropdown>
                  </div>
                </div>
                <p *ngIf="stepDone" class="deadline-title">
                  <ng-container *ngIf="samples[0][0] === '8'">
                    {{ getTranslation(getDeadline(order.deadlines ? order.deadlines[0].deadline_id : null, samples[0])?.text | translate) }}
                  </ng-container>
                  <ng-container *ngIf="samples[0][0] !== '8'">
                    {{ getDeadline(order.deadlines ? order.deadlines[0].deadline_id : null, samples[0])?.text | translate }}
                  </ng-container>
                </p>
              </ng-container>
            </div>
            <ng-container *ngIf="order.labels_path != null">
              <div class="p-col-12 p-md-2">
                <app-prolabo-icon-button
                  icon="printer"
                  type="primary"
                  [name]="'ETIQUETTE'"
                  [disabled]="isCanceled"
                  (OnClick)="openEtiquets(1)">
                </app-prolabo-icon-button>
              </div>
            </ng-container>
            <ng-container *ngIf="order.accompanying_sheet_path != null">
              <div class="p-col-12 p-md-2">
                <app-prolabo-icon-button
                  icon="printer"
                  type="primary"
                  [name]="'SHEET'"
                  [disabled]="isCanceled"
                  (OnClick)="openEtiquets(0)">
                </app-prolabo-icon-button>
              </div>
            </ng-container>
            <ng-container *ngIf="stepDone && samples[0] === '2'">
              <div class="p-col-12 p-md-2">
                <app-prolabo-icon-button
                  icon="printer"
                  type="primary"
                  name="EXPORT_SAMPLES"
                  [disabled]="isCanceled"
                  (OnClick)="exportSamples()">
                </app-prolabo-icon-button>
              </div>
            </ng-container>
            <div class="p-col-12 p-md-2">
              <app-prolabo-icon-button
                icon="add"
                type="primary"
                name="ADD.NEW.SAMPLE"
                (OnClick)="createSample(samples[0])"
                [disabled]="isCanceled">
              </app-prolabo-icon-button>
            </div>
            <ng-container *ngIf="step3 || step4 || stepDone">
              <div class="p-col-12 p-md-2">
                <app-prolabo-icon-button
                  icon="check"
                  type="secondary"
                  [disabled]="isCanceled"
                  [name]="samples[1].analyzed ? 'ANALYSED_BTN' : 'ANALYSE' "
                  (OnClick)="openScoreCard(samples[1].samples,samples[1].analyzed , false)">
                </app-prolabo-icon-button>
              </div>
            </ng-container>
          </div>
          <div class="p-grid">
            <div class="p-col-12">
              <ng-container *ngIf="order">
                <app-prolabo-pagination-list-simple-samples
                  [items]="samples[1].samples"
                  [step2]="step2"
                  [sampleAnalyzed]="samples[1].analyzed"
                  [step3]="step3"
                  [step4]="step4"
                  [isVdi]="samples[0] === '1'"
                  [stepDone]="stepDone"
                  [headers]="samplesHeader"
                  [user]="false"
                  [order]="order"
                  [isCanceled]="isCanceled"
                  (getOrder)="getOrderDetails(this.orderId)"
                  [scoreCardModels]="getScoreCardModelHeaders(samples[0])"
                  (itemDelete)="handleDelete($event)"
                  (itemToEdit)="handleSampleUpdate($event)">
                </app-prolabo-pagination-list-simple-samples>
              </ng-container>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="samplesByType && samplesByType.length == 0">
      <p-card>
        <div class="p-grid spacing-bottom">
          <div class="p-col-12 p-md-8 title">
            <p-header>
              {{ 'SAMPLING' | translate }}
            </p-header>
          </div>
          <div class="p-col-12 p-md-4">
            <app-prolabo-icon-button
              icon="add"
              type="primary"
              name="ADD.NEW.SAMPLE"
              [disabled]="step3"
              (OnClick)="createSample(null)">
            </app-prolabo-icon-button>
          </div>
        </div>
        <div class="p-grid">
        </div>
      </p-card>
    </ng-container>
  </div>


  <div class="p-col-12 p-xl-4" *ngIf="order">
    <app-prolabo-simple-order-administration [submitClicked]="initiatedSubmit"
                                             (clientEvent)="handleClientEvent($event)"
                                             [order]="order"
                                             [laboratories]="laboratories"
                                             [invoiceTypes]="invoiceTypes"
                                             [isCanceled]="isCanceled"
                                             (orderEvent)="handleOrderError($event)"
                                             (requestGetOrder)="getOrderDetails(order.id)"
                                             (orderFormEvent)="handleOrderFormEvent($event)">
    </app-prolabo-simple-order-administration>
    <app-prolabo-simple-order-report [submitClicked]="initiatedSubmit"
                                     (emailEvents)="handleEmailEvent($event)"
                                     (email)="handleEmailError($event)"
                                     (languageEvent)="handleLanguageEvent($event)"
                                     (requestGetOrder)="getOrderDetails(order.id)"
                                     [order]="order"
                                     [isCanceled]="isCanceled"
                                     [getOrderRequested]="getOrderInitiated"
                                     (lang)="handleLangError($event)"
                                     [client]="selectedClient">
    </app-prolabo-simple-order-report>
  </div>
</div>
<app-prolabo-confirmation-modal
  [display]="displayModal"
  [header]="'MODAL_CONFIRMATION'"
  (confirmClicked)="reActivateOrder()"
  (cancelClicked)="closeConfirmDialog()">
</app-prolabo-confirmation-modal>

<app-prolabo-confirmation-modal
  [display]="displayModal2"
  [header]="'MODAL_CONFIRMATION_CANCEL'"
  (confirmClicked)="cancelOrder()"
  (cancelClicked)="closeConfirmDialog2()">
</app-prolabo-confirmation-modal>
