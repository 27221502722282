import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {DropdownModel} from 'src/app/shared/components/dropdown/dropdown.model';
import {AdminStatics} from '../../../../statics/admin-statics';
import {ToastService, UserService} from '../../../../../shared/services';
import {User} from '../../../../models/user';
import {TranslateService} from '@ngx-translate/core';
import {Laboratory} from '../../../../../models/laboratorie';
import {AdminDashboardService} from '../../../../services/admin-dashboard.service';
import {AdminSimpleOrdersService} from '../../../../services/admin-simple-orders.service';
import {Type} from '../../../../models/type';
import {ResourcesService} from '../../../../../shared/services/resources.service';
import {OrderOwner} from '../../../../models/simple-order-response';
import {DropdownComponent} from '../../../../../shared/components/dropdown/dropdown.component';
import {Deadline} from '../../../../../models/deadline';

@Component({
  selector: 'app-prolabo-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {
  currentUser: User;
  isUser = false;
  laboratories: DropdownModel[] = [];
  laboratoryId = -1;
  deadlines: DropdownModel[] = [];
  deadlineId = -1;
  sampleTypeId = -1;
  next = false;
  sampleTypes: DropdownModel[] = [];
  searchParams: any;
  limit = 10000;
  offset = 0;
  orderId: any;
  users: DropdownModel[] = [];
  userId = 1;
  isAsbestos = false;
  @ViewChild('laboratory') laboratoryField: DropdownComponent;

  constructor(public ref: DynamicDialogRef,
              private userService: UserService,
              private toast: ToastService,
              private dashboardService: AdminDashboardService,
              private translateService: TranslateService,
              private simpleOrdersService: AdminSimpleOrdersService,
              private resources: ResourcesService,
              public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.currentUser = this.userService.getCurrentUser();
    this.isUser = this.config.data?.user;
    this.getUsers();
    this.orderId = this.config.data.orderId;
    if (this.config.data?.laboratories) {
      this.setLaboratories(this.config.data?.laboratories);
    }

    if (this.config.data?.sampleTypes) {
      this.setSampleTypes(this.config.data.sampleTypes);
    }
  }

  getUsers() {
    if (!this.isUser) {
      const params: any = {
        locale: this.resources.getLanguage().description,
      };

      if (this.searchParams) {
        params.search = this.searchParams;
      }

      // if this limit is removed, the default pagination from the API is applied
      params[AdminStatics.limit] = this.limit;
      params[AdminStatics.offset] = this.offset;
      this.simpleOrdersService.getAllUsers(params).subscribe(result => {
        this.setUsers(result.users);
      });
    }
  }

  updateUserId($event: string) {
    this.userId = +$event;
  }

  onFilter($event) {
    const message = $event.originalEvent.path[3].innerText.toString();
    if (message.includes('No results found')) {
      this.searchParams = $event.filter;
      this.getUsers();
    } else if ($event.filter == null) {
      this.searchParams = null;
      this.getUsers();
    }
  }

  private setUsers(users: OrderOwner[]) {
    this.users = new Array(0);
    users.forEach(user => {
      this.users.push({
        id: user.id,
        text: this.setUser(user),
        value: user.id
      });
    });
  }

  setUser(user: OrderOwner) {
    if (user.is_company === 1) {
      return user.first_name + ' ' + user.last_name + ' | ' + user.company;
    } else {
      return user.first_name + ' ' + user.last_name;
    }
  }

  updateLaboratoriesEvent(value) {
    this.laboratoryId = value;
  }

  updateSampleTypeEvent(value) {
    this.sampleTypeId = value;
    if (value === 2) {
      this.isAsbestos = true;
      this.simpleOrdersService.getDeadlines({
        locale: this.resources.getLanguage().description,
        sample_type_id: 2,
        user_id: this.resources.getStorageUser().id
      }).subscribe(x => {
        this.setDeadlines(x.deadlines);
        this.deadlineId = x.deadlines[0].id;
        this.simpleOrdersService.getAvailableLaboratories({
          locale: this.resources.getLanguage().description,
          sample_type_id: this.sampleTypeId,
          deadline_id: this.deadlineId,
        }).subscribe(y => {
          this.setLaboratories(y.laboratories);

          if (y.laboratories.length === 1) {
            this.laboratoryId = this.laboratories[1].id;
          }
        });
      });
    } else {
      this.isAsbestos = false;
      this.deadlineId = -1;
      this.simpleOrdersService.getAvailableLaboratories({
        locale: this.resources.getLanguage().description,
        sample_type_id: this.sampleTypeId
      }).subscribe(x => {
        this.setLaboratories(x.laboratories);

        if (this.sampleTypeId === 8) {
          this.laboratoryField.selectedOptionFormControl.setValue(this.laboratories[1].id);
        }

        if (x.laboratories.length === 1) {
          this.laboratoryId = this.laboratories[1].id;
        }
      });
    }

  }

  createSimple() {
    this.ref.close({type: AdminStatics.simpleOrder, laboratory_id: this.laboratoryId});
  }

  createTemp() {
    this.ref.close({laboratory_id: this.laboratoryId, type: AdminStatics.simpleOrder, deadline_id: this.deadlineId, sample_type_id: this.sampleTypeId});
  }

  createTemp2() {
    this.ref.close({user_id: this.userId, type: AdminStatics.simpleOrder});
  }

  createDiagnostics() {
    this.ref.close({type: AdminStatics.diagnostics, laboratory_id: this.laboratoryId});
  }

  private setLaboratories(laboratories: Laboratory[]) {
    this.laboratories = new Array(0);
    this.laboratories.push({
      id: -1,
      text: this.translateService.instant('CHOOSE_BRANCH'),
      value: -1
    });
    laboratories.forEach(laboratory => {
      this.laboratories.push({
        id: laboratory.id,
        text: laboratory.city + ' ' + this.getSamplesTypesForBranch(laboratory.city),
        value: laboratory.id
      });
    });
    if (this.currentUser.role.id === 5) {
      this.laboratories = this.laboratories.filter(x => x.text.toLowerCase().includes('lenzburg'));
      this.laboratories.push({
        id: -1,
        text: this.translateService.instant('CHOOSE_BRANCH'),
        value: -1
      });
    }
  }

  private setSampleTypes(sampletypes: Type[]) {
    this.sampleTypes.push({
      id: -1,
      text: this.translateService.instant('SELECT'),
      value: -1
    });
    sampletypes.forEach(sampleType => {
      this.sampleTypes.push({
        id: sampleType.id,
        text: sampleType[this.resources.getLanguage().description],
        value: sampleType.id
      });
    });
  }

  private getSamplesTypesForBranch(city): string {
    switch (city) {
      case 'Sion':
        return `( ${this.translateService.instant('VDI')},
      ${this.translateService.instant('ASBESTOS')},
      ${this.translateService.instant('LEAD')},
      ${this.translateService.instant('PCB')},
      ${this.translateService.instant('PCB_CP')},
      ${this.translateService.instant('HAP')},
      ${this.translateService.instant('QUANTITATIVE_BUFFER')} )`;
      case 'Lenzburg':
        return `( ${this.translateService.instant('VDI')},
      ${this.translateService.instant('ASBESTOS_PLUS')},
      ${this.translateService.instant('QUANTITATIVE_BUFFER')} )`;
      default:
        return `( ${this.translateService.instant('VDI')},
      ${this.translateService.instant('ASBESTOS')},
      ${this.translateService.instant('QUANTITATIVE_BUFFER')} )`;
    }
  }

  private setDeadlines(types: Deadline[]) {
    this.deadlines = new Array(0);
    types.forEach(type => {
      this.deadlines.push({
        id: type.id,
        text: this.translateService.instant(type.slug),
        value: type.id
      });
    });
  }

  close() {
    this.ref.close();
  }

  previous() {
    this.next = false;
  }

  updateDeadlineEvent($event) {
    this.deadlineId = $event;
    this.simpleOrdersService.getAvailableLaboratories({
      locale: this.resources.getLanguage().description,
      sample_type_id: this.sampleTypeId,
      deadline_id: this.deadlineId,
    }).subscribe(x => {
      this.setLaboratories(x.laboratories);

      if (x.laboratories.length === 1) {
        this.laboratoryId = this.laboratories[1].id;
      }
    });
  }
}
